<template>
    <v-card max-width="328" outlined class="my-2" @click="gotoProject(project)" hover>
        <div class="space-between">
            <div class="text-wrapper">
                <v-list>
                    <v-list-item-content>
                        <v-list-item-title class="mx-5 font-weight-bold headline card-title">{{
                            displayProject.name
                        }}
                        </v-list-item-title>
                        <span class="ml-5 projectStatus" :style="project.isOnBoardingActive ? 'color:red' : ''"> 
                            <img class="mr-1" :src="project.isOnBoardingActive ? require('@/assets/icons/error-status.svg') : require('@/assets/icons/success-status.svg') "> </img>
                            {{ project.isOnBoardingActive ? 'Ждёт настроек' : 'Активный' }} 
                        </span>
                    </v-list-item-content>
                </v-list>
            </div>
            <div>
                <v-menu :close-on-content-click="true" offset-x offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon class="mr-10px mt-5" v-if="someActionsAvailable"
                            ><v-icon> mdi-dots-vertical </v-icon></v-btn
                        >
                    </template>

                    <v-card min-width="198px">
                        <v-list>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="addUser()"
                                :disabled="!checkAccess(project.id, 'owner')"
                            >
                                <span class="mx-3 my-2">Добавить пользователя</span>
                            </v-list-item>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="deleteProject()"
                                :disabled="!checkRole(project.id, 'project:delete')"
                            >
                                <span class="mx-3 my-2">Удалить проект</span>
                            </v-list-item>
                            <v-list-item
                                class="text-btn-wrapper"
                                @click="renameProject()"
                                :disabled="!checkRole(project.id, 'project:rename')"
                            >
                                <span class="mx-3 my-2">Переименовать</span>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div>
            <div class="edit-image-wrapper">
                <v-btn
                    v-if="checkRole(project.id, 'project:changeImage')"
                    class="edit-image"
                    fab
                    icon
                    outlined
                    color="white"
                    plain
                    small
                    @click.stop="changeProjectImage"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-img
                    v-if="!project.image"
                    :width="328"
                    :height="169"
                    contain
                    src="@/assets/img/project-image.svg"
                ></v-img>
                <v-img v-if="project.image" :width="328" :height="169" :src="project.image.src"></v-img>
            </div>
        </div>
        <div class="pa-5">
            <span>Доступен:</span>
            <div class="ml-1 mt-2">
                <v-row>
                    <template v-for="user in displayProject.available">
                        <v-avatar v-if="!user.isPending" :key="user.id" size="36" class="mx-1 mt-1">
                            <v-img transition="slide-x-transition" :src="user.avatar" :alt="user.email" />
                        </v-avatar>
                        <v-avatar v-else :key="user.id" size="36" class="mx-1 mt-1" color="#c7dfff">
                            <v-icon>mdi-account</v-icon>
                        </v-avatar>
                    </template>

                    <v-avatar
                        color="#0000000b"
                        v-if="displayProject.avatarsDelete > 0"
                        size="36"
                        class="mx-1 my-1"
                    >
                        <span>+{{ displayProject.avatarsDelete }}</span>
                    </v-avatar>
                </v-row>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { checkAccess } from '@/utils'
import { checkRole } from '@root/src/helpers/roles'
export default {
    name: 'ProjectCard',
    props: {
        project: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {},
    data: () => ({
        displayProject: {},
    }),
    watch: {
        project() {
            this.setDisplayProject()
        },
    },
    computed: {
        owner() {
            if (!this.displayProject.owner) {
                return null
            }
            if (this.displayProject.owner.id === this.$auth.user.sub) {
                return 'Я'
            } else {
                return this.displayProject.owner.name
            }
        },
        someActionsAvailable() {
            return (
                this.checkAccess(this.project.id, 'owner') ||
                checkRole(this.project.id, 'project:delete') ||
                checkRole(this.project.id, 'project:rename')
            )
        },
    },
    methods: {
        checkAccess,
        checkRole,
        setDisplayProject() {
            function calculateAvatarsCount() {
                const availableThWidth = 326
                const count = (availableThWidth / 52).toFixed(0) - 1 // ~40 = avatar width ; 8-margins ; 3 - safety offset
                return count
            }

            const targetAvatarsCount = calculateAvatarsCount()

            const availableClone = JSON.parse(JSON.stringify(this.project.users))

            const startAvatarCount = availableClone.length
            let deleteCount = 0
            if (startAvatarCount > targetAvatarsCount) {
                deleteCount = startAvatarCount - targetAvatarsCount
                for (let i = 0; i < deleteCount; i++) {
                    availableClone.pop()
                }
            }
            const displayProject = {
                id: this.project.id,
                name: this.project.name,
                owner: this.project.owner,
                available: availableClone,
                avatarsDelete: deleteCount,
            }
            this.displayProject = displayProject
        },
        addUser() {
            this.$parent.$emit('addUser', this.project.id)
        },
        gotoProject(project) {
            const onboardingActive = project.isOnBoardingActive
            if (onboardingActive) this.$router.push(`/project/${project.id}/onBoarding`)
            else this.$router.push(`/project/${project.id}`)
        },
        deleteProject() {
            this.$parent.$emit('deleteProject', this.project.id)
        },
        renameProject() {
            this.$parent.$emit('renameProject', this.project.id)
        },
        changeProjectImage() {
            this.$parent.$emit('changeProjectImage', this.project.id)
        },
    },

    created() {},
    mounted() {
        this.setDisplayProject()
    },
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
thead
    background-color: #0000000b

.list-view
    max-width: 1500px

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1
    width: 100%
    padding-top: 0 !important
    padding-bottom: 0 !important
    padding-left: 12px !important
    padding-right: 12px !important
    /* padding: 12px; */

.text-wrapper
    max-width: 280px

.projectStatus
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start

.space-between
    display: flex
    justify-content: space-between

.v-list-item
    padding: 0 !important
    min-height: 0px

button:focus
    outline: 0

.edit-image-wrapper
    height: 169px
    position: relative
    display: flex
    sjustify-content: center
    sjustify-items: center
    salign-items: center

.edit-image
    position: absolute
    z-index: 2
    border: none
    right: 16px
    top: 16px
    background-color: #2A3B5114
</style>
