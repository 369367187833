<template>
    <v-dialog v-model="isChangeProjectImageDialog" width="500">
        <v-card tile>
            <v-card-title class="headline main white--text"> Изменить логотип проекта </v-card-title>
            <v-card-text>
                <v-form v-model="valid" class="mt-6" ref="form">
                    <v-file-input
                        :rules="rules"
                        :loading="loading"
                        v-model="file"
                        filled
                        label="Выберете файл"
                    ></v-file-input>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn
                    color="main"
                    class="ml-3 mb-5 white--text"
                    @click="changeProjectImage"
                    :disabled="!valid"
                    :loading="loading"
                >
                    Применить
                </v-btn>
                <v-btn outlined class="ml-4 mb-5" @click="cancel"> Отмена </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
//DEPRECATED
export default {
    name: 'ChangeProjectImageDialog',
    components: {},
    props: {
        projectId: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        file: null,
        maxFileSize: 1 * 1024 * 1024, // 1 мб
    }),
    computed: {
        ...mapState('project', ['isChangeProjectImageDialog']),
        isChangeProjectImageDialog: {
            get() {
                return this.$store.state.project.isChangeProjectImageDialog
            },
            set(value) {
                this.$store.commit('project/setIsChangeProjectImageDialog', value)
            },
        },
        project() {
            return this.$store.getters.projectById(this.projectId)
        },
        rules() {
            const r1 = (v) => !!v || 'Выберете файл'
            const r2 = (v) => this.fileIsPngOrJpg(v) || `Файл должен быть в формате jpg, jpeg или png`
            const r3 = (v) => this.fileSizeIsValid(v) || `Макимальный размер файла - 1 мб`
            return [r1, r2, r3]
        },
    },
    watch: {
        isChangeProjectImageDialog() {
            this.projectName = this.project.name
            this.file = null
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        file() {
            console.log(this.file)
        },
    },
    methods: {
        async changeProjectImage() {
            this.loading = true
            await this.$store.dispatch('project/changeProjectImage', {
                projectId: this.project.id,
                file: this.file,
            })
            this.loading = false
            this.cancel()
        },
        fileIsPngOrJpg(file) {
            if (!file) {
                return false
            }
            const type = file.type.split('/')[1]
            return type === 'jpg' || type === 'png' || type === 'jpeg'
        },
        fileSizeIsValid(file) {
            if (!file) {
                return false
            }
            return file.size < this.maxFileSize
        },
        cancel() {
            this.$store.commit('project/setIsChangeProjectImageDialog', false)
        },
    },

    created() {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
button
    border-color: #0000001F
</style>
