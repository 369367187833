<template>
    <v-card tile>
        <v-card-title class="headline main white--text"> Добавить пользователя </v-card-title>
        <v-card-text>
            <v-form v-model="valid" class="mt-6" ref="form">
                <v-text-field
                    color="black"
                    prepend-inner-icon="mdi-email"
                    filled
                    :rules="rules"
                    v-model="userEmail"
                    label="Введите email пользователя"
                    required
                ></v-text-field>
            </v-form>
            <v-row>
                <button
                    class="user-btn ma-2"
                    :class="{ 'user-btn-active': activeRoleId === roles[0].id }"
                    @click="chooseUserRole(roles[0].id)"
                >
                    {{ roles[0].name }}
                </button>
                <button
                    class="user-btn ma-2"
                    :class="{ 'user-btn-active': activeRoleId === roles[1].id }"
                    @click="chooseUserRole(roles[1].id)"
                >
                    {{ roles[1].name }}
                </button>
                <button
                    class="user-btn ma-2"
                    :class="{ 'user-btn-active': activeRoleId === roles[2].id }"
                    @click="chooseUserRole(roles[2].id)"
                >
                    {{ roles[2].name }}
                </button>
            </v-row>
        </v-card-text>

        <v-card-actions>
            <v-btn color="main" class="ml-3 mb-5 white--text" :disabled="!valid" @click="addUser">
                Добавить
            </v-btn>
            <v-btn outlined class="ml-4 mb-5" @click="cancel"> Отмена </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'AddUserDialog',
    props: {
        projectId: String,
    },
    components: {},
    data: () => ({
        userEmail: '',
        activeRoleId: 'observer',
        valid: false,
    }),
    computed: {
        ...mapState(['roles']),
        rules() {
            const r1 = (v) => !!v || 'Введите email'
            const r2 = (v) =>
                /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'email должен быть валидным'
            const r3 = (v) =>
                (v && !this.emailsInProject.includes(v.toLowerCase())) || 'Этот участник уже добавлен'
            return [r1, r2, r3]
        },
        emailsInProject() {
            return this.$store.getters.projectById(this.projectId).users.map((user) => user.email)
        },
    },

    methods: {
        chooseUserRole(roleId) {
            this.activeRoleId = roleId
        },
        async addUser() {
            //ACTION добавления пользователя
            const payload = {
                projectId: this.projectId,
                email: this.userEmail,
                roleId: this.activeRoleId,
            }
            await this.$store.dispatch('project/assignPermission', payload)
            await this.$store.dispatch('project/setProjects')
            /*          setTimeout(async () => {
                await this.$store.dispatch('project/setProjects')
                window.location.reload()
            }, 1500) */

            this.cancel()
        },
        cancel() {
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
            this.$store.commit('project/updateIsAddUserDialog', false)
        },
    },

    created() {},
    mounted() {},
}
</script>

<style lang="sass" scoped>
@import '@/vars.sass'
button
    border-color: #0000001F
    outline: 0
.user-btn
    border: 1px solid #0000001F
    border-radius: 16px
    color: black
    padding: 4px 12px 4px 12px
.user-btn-active
    border: 1px solid $main
    background-color: $main-light
</style>
