<template>
    <div class="project-display-btns">
        <button
            class="project-display-btn project-display-btn-left"
            :class="{ 'project-display-btn-active ': this.value === 'list' }"
            @click="setView('list')"
        >
            <v-icon :color="listViewColor"> mdi-format-list-bulleted </v-icon>
        </button>
        <button
            class="project-display-btn project-display-btn-right"
            :class="{ 'project-display-btn-active ': this.value === 'cards' }"
            @click="setView('cards')"
        >
            <v-icon :color="cardsViewColor"> mdi-view-module </v-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ChangeViewBtn',
    props: {
        value: {
            type: String,
            default: 'cards',
        },
        //Если указано, значение будет кешироваться в localStorage
        cacheKey: {
            type: String,
            default: null,
        },
    },
    components: {},
    data: () => ({}),
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
        listViewColor: function () {
            if (this.value === 'list') {
                return 'black'
            } else {
                return null
            }
        },
        cardsViewColor: function () {
            if (this.value === 'cards') {
                return 'black'
            } else {
                return null
            }
        },
    },
    methods: {
        setView(view) {
            if (this.cacheKey) {
                localStorage.setItem(this.cacheKey, view)
            }
            this.inputVal = view
        },
    },
    created() {
        if (this.cacheKey) {
            const cachedValue = localStorage.getItem(this.cacheKey)
            if (cachedValue) {
                this.setView(cachedValue)
            }
        }
    },
}
</script>

<style lang='sass' scoped>
@import '@/vars.sass'
.project-display-btns
    font-size: 0
.project-display-btn
    width: 36px
    height: 36px
    border: 1px solid #0000001f

    &-left
        border-radius: 4px 0 0 4px

    &-right
        border-radius: 0 4px 4px 0

    &:focus
        outline: 0
    &:hover
        background-color: $main-light
        border: 1px solid $main

    &-active
        background-color: $main-light
        border: 1px solid $main
</style>
