<template>
    <v-tour name="getStarted" :steps="steps" ref="tour">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step
                    v-if="tour.steps[tour.currentStep]"
                    :key="tour.currentStep"
                    :step="tour.steps[tour.currentStep]"
                    :previous-step="tour.previousStep"
                    :next-step="tour.nextStep"
                    :stop="tour.stop"
                    :skip="tour.skip"
                    :is-first="tour.isFirst"
                    :is-last="tour.isLast"
                    :labels="tour.labels"
                    :highlight="tour.highlight"
                >
                    <template>
                        <div slot="header"></div>
                    </template>
                    <template>
                        <div slot="content">
                            <div class="step-content-wrapper">
                                <img class="mb-12" src="@/assets/icons/tour-arrow-left.svg" />

                                <p class="white--text ml-2 tour-text">
                                    {{ tour.steps[tour.currentStep].content }}
                                </p>
                            </div>
                            <div class="step-content-wrapper" style="margin-left: 75px">
                                <v-btn color="white" v-if="tour.isLast" outlined @click="finishTour"
                                    >ПРОПУСТИТЬ</v-btn
                                >
                                <v-btn color="white" v-else outlined @click="tour.nextStep">ДАЛЕЕ</v-btn>
                            </div>
                        </div>
                    </template>
                    <template>
                        <div slot="actions">
                            <v-row> </v-row>
                        </div>
                    </template>
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>

<script>
export default {
    name: 'Tour',
    props: ['steps'],
    data: () => ({}),
    computed: {},

    methods: {
        finishTour() {
            this.$refs.tour.stop()
            localStorage.setItem('getStartedTourIsFinished', true)
        },
    },
    mounted() {},
}
</script>
<style scoped lang="sass">
.step-content-wrapper
    display: flex

::v-deep .v-step
    //z-index: 5
    background-color: transparent !important
    box-shadow: none !important
    color: white !important
    max-width: 400px !important
    max-height: 300px !important
::v-deep .v-step__content
    background-color: transparent !important
::v-deep .v-step__header
    background-color: transparent !important
::v-deep .tour-text
    font-size: 13px
    font-style: italic
    text-align: start
</style>
