type answersList = { id: number; name: string }[]

export const clientFormHelper = {
    mapAnswers: (
        reason: string,
        answers: any,
        answersList: { q1Answers: answersList; q2Answers: answersList }
    ) => {
        return {
            phone: answers.phone.replace(/[^0-9+]/g, ''),
            functions: answers.functions
                .map((aId: number) => answersList.q1Answers.find(a => a.id === aId)?.name)
                .join(`,\n`),
            businessType:
                answers.businessType === 8
                    ? answers.customBusinessType
                    : answersList.q2Answers.find(a => a.id === answers.businessType)?.name,
        }
    },
}
