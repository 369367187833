
import qs from 'query-string'
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from 'vue'
import BaseInputOutlined from '../inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '../inputs/CustomActivatorSelect.vue'
import api from '../../api/api'
import { clientFormHelper } from './helpers'
export default defineComponent({
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        reason: {
            type: String,
            required: true,
        },
        extra: {
            type: null,
        },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const loading = ref(false)
        const projectId = computed(() => root.$route.params.id)
        const answers = ref({
            phone: '',
            functions: [],
            businessType: null,
            customBusinessType: '',
        })
        const q1Answers = [
            { id: 1, name: 'Автоматизированно рассылать напоминания о записях клиентам' },
            { id: 2, name: 'Автоматизированно получать отзывы и подтверждения записей от клиентов' },
            { id: 3, name: 'Организовать обзвон клиентов по сегментам' },
            { id: 4, name: 'Мотивировать сотрудников на обзвон клиентов по сегментам' },
            { id: 5, name: 'Делать рассылки клиентам по сегментам' },
            { id: 6, name: 'Анализировать работу сотрудников по возвращению клиентов' },
            { id: 7, name: 'Анализировать состояние моего бизнеса' },
        ]
        const q2Answers = [
            { id: 1, name: 'Барбершоп' },
            { id: 2, name: 'Салон красоты' },
            { id: 3, name: 'Маникюрная студия' },
            { id: 4, name: 'Студия эпиляции' },
            { id: 5, name: 'Косметологическая клиника' },
            { id: 6, name: 'Стоматологическая клиника' },
            { id: 7, name: 'Многопрофильная клиника' },
            { id: 8, name: 'Прочее' },
        ]

        const buttonDisabled = computed(() => {
            if (answers.value.businessType === 8 && !answers.value.customBusinessType) return true
            return !answers.value.phone || !answers.value.functions.length || !answers.value.businessType
        })

        const saveAnswers = async () => {
            loading.value = true
            const mappedAnswers = clientFormHelper.mapAnswers(props.reason, answers.value, {
                q1Answers,
                q2Answers,
            })

            await api.clientForm.saveAnswers({
                answers: answers.value,
                reason: props.reason,
                mappedAnswers,
                projectId: projectId.value,
                extra: props.extra,
            })
            emit('answers-saved')
            loading.value = false
        }

        return { q1Answers, q2Answers, answers, loading, saveAnswers, buttonDisabled }
    },
})
